import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from '../../hooks/useRefreshToken';
import useAuth from '../../hooks/useAuth';
import useLocalStorage from "../../hooks/useLocalStorage";
import MainOutline from "./MainOutline";
import { Grid } from 'react-loading-icons'
import { LoadingWrapper } from "./QuoteElements";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();
    const [persist] = useLocalStorage('persist', false);
    const showLogout = true;

    useEffect(() => {
        let isMounted = true;

        const verifyRefreshToken = async () => {
            try {
                await refresh();
            }
            catch (err) {
                console.error(err);
            }
            finally {
                isMounted && setIsLoading(false);
            }
        }

        // persist added here AFTER tutorial video
        // Avoids unwanted call to verifyRefreshToken
        !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

        return () => isMounted = false;
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // console.log(`isLoading: ${isLoading}`)
        // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`)
    }, [isLoading])

    return (
        <>
            {!persist
                ? 
                <>
                    <MainOutline showLogout={showLogout}/>
                    <Outlet />
                </>
                : isLoading
                    ? 
                    <>
                        <LoadingWrapper>
                            <Grid />
                        </LoadingWrapper>
                        
                    </>
                    : <>
                        <MainOutline showLogout={showLogout}/>
                        <Outlet />
                    </>
            }
        </>
    )
}

export default PersistLogin