import './App.css';
import { 
  BrowserRouter as Router, 
  Routes, 
  Route
} from 'react-router-dom';
import Build from './components/Quote';
import '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import Login from './components/Quote/Login';
import PersistLogin from './components/Quote/PersistLogin';
import Quote from './components/Quote/Quote';
import Confirm from './components/Quote/Confirm';
import RequireAuth from './components/Quote/RequireAuth';
import TagManager from "react-gtm-module";

function App() {

  const [status, setStatus] = useState(false);

  TagManager.initialize({'gtmId': "GTM-MD88S8R"})

  TagManager.dataLayer({
      gtmId: "GTM-MD88S8R",
      dataLayer: {
          event: 'pageview',
      }
  });

  useEffect(() => {
    window.addEventListener('beforeunload', localStorage.clear())
   
    return () => {
    window.removeEventListener('beforeunload', localStorage.clear())
  };
  }, []);
  return (
    <Router>
      <Routes>
        <Route path='/' element={
            <Build 
              status={status} 
              setStatus={setStatus}
            />
          } 
        />
        <Route path='/:confirmationCode' element={ <Confirm /> }/>
        <Route path='login' element={ <Login /> }/>

        {/* Protected Routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path='quote' element={<Quote />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
