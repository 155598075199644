export const FinanceData = [
    {
        "Lender": "Sunnova",
        "APR": 0,
        "Fee": 0,
        "Term": 25,
        "Option": "Sunnova 25 Year Lease",
        "Type": "Lease"
    },
    // {
    //     "Lender": "Climate First",
    //     "APR": 8.09,
    //     "Fee": 0,
    //     "Term": 25,
    //     "Option": "Climate First 25 Year 8.09% APR - No Fee",
    //     "Type": "Loan"
    // },
    {
        "Lender": "Sunnova",
        "APR": 3.99,
        "Fee": 33.99,
        "Term": 25,
        "Option": "Sunnova 25 Year 3.99% APR - 33.99% Fee",
        "Type": "Loan"
    },
    {
        "Lender": "Dividend",
        "APR": 8.99,
        "Fee": 0,
        "Term": 25,
        "Option": "Dividend 25 Year 8.99% APR - No Fee",
        "Type": "Loan"
    },
    {
        "Lender": "Cash",
        "APR": 0,
        "Fee": 0,
        "Term": 0,
        "Option": "None - Cash",
        "Type": "Cash"
    },
]