export const LeaseData = [
    {
        "State": "FL",
        "Rate": .112,
    },
    {
        "State": "MA",
        "Rate": .19,
    },
    {
        "State": "CT",
        "Rate": .182,
    },
    {
        "State": "RI",
        "Rate": .177,
    },
]