import { 
    CounterMinusIcon, 
    CounterNumber, 
    CounterPlusIcon, 
    CounterWrapper, 
    DownloadLink, 
    GridColumn,
    NextButton, 
    QuoteTextSubtitle, 
    QuoteTextTitle, 
    SolarImages 
} from "./QuoteElements";

export const ModuleInfo = ({
    formState, 
    responseState,
    moduleAmount, 
    systemSize, 
    offset, 
    selected,  
    subtotal, 
    nextButton, 
    setSelected,
    setSystemSize,
    setOffset,
    setModuleAmount,
    setSubtotal,
    batteryCost,
    module
}) => { 
    // ModuleData.map((module) => {
        const module_amount = Math.ceil((responseState.estimateSize * 1000) / module.kw);
        const module_size = (module_amount*module.kw)/1000;
        const module_offset = ((module_size/responseState.estimateSize)*100).toFixed(0);

        var module_subtotal = 0;
        if(formState.state === 'FL') module_subtotal = module_size*module.rateFL;
        else module_subtotal = module_size*module.rateNE;    

        const handleSelect = (moduleParam) => {
            setSelected(moduleParam);
            if (moduleParam === module.module){
                setSystemSize(module_size);
                setOffset(module_offset);
                setModuleAmount(module_amount);
                setSubtotal(module_subtotal);
            }
        
        }

        function updateModuleValues (moduleParam, operator){
            if(moduleAmount >= 0){
                // var kw = 0;
                // var rate = 0;
                // if(moduleParam === module.module){
                //     kw = modules.qcellKw;
                //     if(formState.state === 'FL'){
                //         rate = modules.qcellRate_FL; 
                //     }
                //     else{
                //         rate = modules.qcellRate_NE; 
                //     }
                    
                // }
                // if(module ==='solarever'){
                //     kw = modules.solareverKw;
                //     rate = modules.solareverRate;
                //     if(formState.state === 'FL'){
                //         rate = modules.solareverRate_FL; 
                //     }
                //     else{
                //         rate = modules.solareverRate_NE;
                //     }
                // }
                var rate = 0;
                if(formState.state === 'FL') rate = module.rateFL;
                else rate = module.rateNE;

                const newModuleCount = moduleAmount + operator;
                const newSystemSize = ((newModuleCount*module.kw)/1000);
                const newOffset = ((newSystemSize/responseState.estimateSize)*100);
                const newSubtotal = (newSystemSize*rate);

                // console.log('New module =', newModuleCount, ', system size =', newSystemSize, ', new offset=', newOffset, ', newSubtotal=', newSubtotal, 'batteryCost:', batteryCost);

                setModuleAmount(newModuleCount);
                setSystemSize(newSystemSize);
                setOffset(newOffset);
                setSubtotal(newSubtotal);
            }
        }

        return (
            <>
                <GridColumn selected={selected === module.module}>
                    <QuoteTextTitle>{module.brand}</QuoteTextTitle>
                    <br />
                    <SolarImages src={module.image} alt={module.imageAlt} />
                    <br />
                    <QuoteTextSubtitle>{module.name}</QuoteTextSubtitle>
                    <QuoteTextSubtitle>{module.kw} watts</QuoteTextSubtitle>
                    <QuoteTextSubtitle>{module.warranty} year warranty</QuoteTextSubtitle>
                    <DownloadLink href={module.datasheet} download>Specs</DownloadLink>
                    {/* <br /> */}
                    {/* {selected !== module.module ?
                    <NextButton onClick={() => handleSelect(module.module)}>Select</NextButton>
                    :
                    <>
                        <QuoteTextSubtitle>Update amount needed?</QuoteTextSubtitle>
                        <br />
                        <CounterWrapper>
                            <CounterMinusIcon onClick={() => updateModuleValues(module.module,-1)} disabled={!moduleAmount}/>
                        <CounterNumber>{moduleAmount}</CounterNumber>
                            <CounterPlusIcon onClick={() => updateModuleValues(module.module,1)} />
                        </CounterWrapper>
                    </>
                    } */}
                    <br />
                </GridColumn>
                
                {/* <GridColumn selected={selected === 'solarever'}>
                    <QuoteTextTitle>Hyperion 400</QuoteTextTitle>
                    <br />
                    <SolarImages src={solarever} alt={'Hyperion 400'} />
                    <br />
                    <QuoteTextSubtitle>HY-DH108P8-390/410B</QuoteTextSubtitle>
                    <QuoteTextSubtitle>400 watts</QuoteTextSubtitle>
                    <QuoteTextSubtitle>25 year warranty</QuoteTextSubtitle>
                    <DownloadLink href='/files/Hyperion.pdf' download>Specs</DownloadLink>
                    <br />
                    {selected !== 'solarever' ? 
                    <NextButton onClick={() => handleSelect('solarever')}>Select</NextButton>
                    :
                    <>
                        <QuoteTextSubtitle>Update amount needed?</QuoteTextSubtitle>
                        <br />
                        <CounterWrapper>
                            <CounterMinusIcon onClick={() => updateModuleValues('solarever',-1)} disabled={!moduleAmount}/>
                        <CounterNumber>{moduleAmount}</CounterNumber>
                            <CounterPlusIcon onClick={() => updateModuleValues('solarever',1)} />
                        </CounterWrapper>
                    </>
                    }
                    <br />
                </GridColumn> */}
            </>
        )
    // })
}