import React, { useEffect, useRef, useState } from 'react'
import MainOutline from './MainOutline'
import { 
    ButtonContainer, 
    ErrorText, 
    ImageBgMobile, 
    NextButton, 
    QuoteBgMobile, 
    QuoteHeader, 
    QuoteInnerWrapper, 
    QuoteInput, 
    QuoteInputTitle, 
    QuoteInputWrapper, 
    RegistrationErrorWrapper,
    RemeberMeWrapper,
    RememberMeCheckbox,
    RememberMeText
} from './QuoteElements'
import { Oval } from 'react-loading-icons'
import axios from 'axios'
import useAuth from '../../hooks/useAuth';
import useInput from '../../hooks/useInput';
import useToggle from '../../hooks/useToggle';
import { useNavigate } from 'react-router-dom'
import lunex from '../../images/LunexPower.webp';


const Login = () => {

    const { setAuth } = useAuth();
    const navigate = useNavigate();
    // const location = useLocation();
    // const from = location.state?.from?.pathname || "/";


    const initialErrorForm = {
        emailError: true,
        passwordError: true,
    }

    const initialLoginForm = {
        email: '',
        password: ''
    }


    const [loginForm, setLoginForm] = useState(initialLoginForm);
    const [error, setError] = useState(initialErrorForm);
    const [errMesg, setErrMsg] = useState('');
    const [user, resetUser, userAttribs] = useInput('user', '');
    const [pwd, setPwd] = useState('');
    const [check, toggleCheck] = useToggle('persist', false);
    const [submitting, setSubmitting] = useState(false);

    const userRef = useRef();

    useEffect(() => {
        userRef.current.focus();
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])


    const updateLoginForm = (event) => {
        event.preventDefault();

        // console.log("value = ", user);
        const { id, value } = event.target;
        const formKey = id;

        const updatedLoginForm = {...loginForm};
        const updatedErrorState = {...error};

        

        if (id === 'email'){
            if(value.length === 0){
                updatedErrorState.emailError = true;
                updatedLoginForm[formKey] = value;
            }
            // if(!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
            //     updatedErrorState.emailError = true;
            //     // console.log("email invalid");
            //     updatedLoginForm[formKey] = value;
            // }
            else{
                // console.log("")
                updatedErrorState.emailError = false;
                updatedLoginForm[formKey] = value;
            }
        }
        if(id === 'password'){
            if(value.length === 0){
                updatedErrorState.passwordError = true;
                updatedLoginForm[formKey] = value;
            }
            else{
                updatedErrorState.passwordError = false;
                updatedLoginForm[formKey] = value.slice(0,24);
                setPwd(value);
            }
        }
        // console.log("Errors = ", updatedErrorState);

        setLoginForm(updatedLoginForm);
        setError(updatedErrorState);

    }

    const loginButton = async() => {
        setSubmitting(true);


        //   console.log("data = ", data);

        try {
            const response = await axios.post(process.env.REACT_APP_API_URL + '/auth',
                JSON.stringify({ user: user, pwd: pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: true,
                }
            );

            // console.log("resp = ", response);
            const accessToken = response?.data?.accessToken;
            // console.log("access token =", accessToken);
            // const pwd = loginForm.password;
            // const roles = response?.data?.roles;
            setAuth({ user, pwd, accessToken });
            // console.log("auth ", authResp);
            resetUser();
            setPwd('');
            const email = user;
            // console.log("email =", email);
            navigate('/quote', { state:{email: email}});
        } catch (err) {
            // console.log("err ", err);
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg('Login Failed');
            }
        }

        setSubmitting(false);
    }

  return (
    <MainOutline>
        <QuoteBgMobile>
            <ImageBgMobile src={lunex} alt={'Lunex Direct'}/>
        </QuoteBgMobile>
        <QuoteInnerWrapper>
            <QuoteHeader>Log in to your account now</QuoteHeader>
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='email'>Email</QuoteInputTitle>
                <QuoteInput type='text' id='email' ref={userRef} {...userAttribs} />
            </QuoteInputWrapper>
            <br />
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='password'>Password</QuoteInputTitle>
                <QuoteInput type='password' id='password' value={loginForm.password} onChange={updateLoginForm}/>
            </QuoteInputWrapper>
            <br />
            <ButtonContainer>
                {!submitting &&
                    <NextButton onClick={loginButton} disabled={submitting || error.passwordError}>Login</NextButton>
                }
                {submitting &&
                    <NextButton>
                        <Oval height='1.5rem'/>
                    </NextButton>            
                }
            </ButtonContainer>
            <RemeberMeWrapper>
                <RememberMeText>Remember me?</RememberMeText>
                <RememberMeCheckbox 
                    type="checkbox"
                    id="persist"
                    onChange={toggleCheck}
                    checked={check}
                />
            </RemeberMeWrapper>
            {errMesg &&
                <RegistrationErrorWrapper>
                    <ErrorText>{errMesg}</ErrorText>
                </RegistrationErrorWrapper>
            }
        </QuoteInnerWrapper>
    </MainOutline>
  )
}

export default Login