import React, { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import MainOutline from './MainOutline'
import Total from './Total'
import { Grid } from 'react-loading-icons'
import { QuoteHeader3, QuoteInnerWrapper } from './QuoteElements';


const Quote = (email) => {
  const location = useLocation();

  const initialCustomerState = {
    address: '',
    state:'',
    email: '',
    systemSize: '',
    offset: '',
    savings: '',
    moduleCost:'',
    moduleAmount:'',
    moduleType: '',
    batteryAmount: '',
    batteryType: '',
    batteryCost: '',
    batteryOnly: false,
    status: '',
  }

  const initialTotalState = {
    cashPriceBeforeIncentives: 0,
    incentiveAmount: 0,
    totalPriceAfterIncentives: 0,
    leaseMonthlyPayment: 0,
    financedMonthlyPayment: 0,
    financedTotal:0,
    financeOption:''
  }

  const [customerData, setCustomerData] = useState(initialCustomerState);
  const [total, setTotal] = useState(initialTotalState);
  const [loading, setLoading] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [showLogout, setShowLogout] = useState(false);
  
  // const effectRun = useRef(false);

  useEffect(() => {
    // console.log("email ", location.state.email);
    let isMounted = true;
    const controller = new AbortController();

    async function getCustomer(){
      setLoading(true);
      try{
        
        const customer = await axiosPrivate.get('/direct/' + location.state.email, {
          signal: controller.signal
        });
        // console.log("customer=", customer);
        
        if(isMounted){
          const updatedCustomerData = {...customerData};
          updatedCustomerData['address'] = customer.data.address;
          updatedCustomerData['state'] = customer.data.state;
          updatedCustomerData['email'] = customer.data.email;
          if(!customer.data.batteryOnly){
            updatedCustomerData['offset'] = customer.data.energyOffset;
            // let savings =  customer.data.savings.replace(/,/g,'')
            // console.log("savings ", customer.data.savings);
            // updatedCustomerData['savings'] = savings;
            updatedCustomerData['moduleAmount'] = customer.data.moduleAmount;
            updatedCustomerData['moduleCost'] = customer.data.moduleCost;
            updatedCustomerData['moduleType'] = customer.data.moduleType;
            updatedCustomerData['systemSize'] = customer.data.systemSize;
            updatedCustomerData['solarProductionMonthly'] = customer.data.solarProductionMonthly;
            updatedCustomerData['electricBill'] = customer.data.electricBill;
          }
          
          updatedCustomerData['batteryType'] = customer.data.batteryType;
          updatedCustomerData['batteryAmount'] = customer.data.batteryAmount;
          updatedCustomerData['batteryCost'] = customer.data.batteryCost;
          updatedCustomerData['batteryOnly'] = customer.data.batteryOnly;
          
          setCustomerData(updatedCustomerData);

          const updatedTotalState = {...total}
          updatedTotalState['cashPriceBeforeIncentives'] = customer.data.cashPriceBeforeIncentives;
          updatedTotalState['savingsAmount'] = customer.data.savings;
          updatedTotalState['incentiveAmount'] = customer.data.incentiveAmount;
          updatedTotalState['totalPriceAfterIncentives'] = customer.data.totalPriceAfterIncentives;
          updatedTotalState['financedMonthlyPayment'] = customer.data.financedMonthlyPayment;
          updatedTotalState['leaseMonthlyPayment'] = customer.data.leaseMonthlyPayment;
          updatedTotalState['financeOption'] = customer.data.financeOption;
          updatedTotalState['financeType'] = customer.data.financeType;
          updatedTotalState['financedTotal'] = customer.data.financedTotal;
          updatedTotalState['moduleMonthlyPayment'] = customer.data.moduleMonthlyPayment;
          updatedTotalState['batteryMonthlyPayment'] = customer.data.batteryMonthlyPayment;

          setTotal(updatedTotalState);
          setShowLogout(true);

          // console.log("battery amount ", customer.data.batteryAmount);
          // console.log("customer data ", customer.data);
          

        }
      }
      catch(err){
        // How do i navigate to login page here?
      }
      finally {
        if(isMounted){
          setLoading(false);
        }
      }

      
    }

    getCustomer();
    

    return () => {
      isMounted = false;
      
      controller.abort();

  }
  // eslint-disable-next-line
  },[])

  const addressClick = () => {
    // Do nothing
    
  }
  return (
    <MainOutline
      showLogout={showLogout}
    >
      {!loading
        ? (
            <>
                <Total 
                  addressClick={addressClick}
                  address={customerData.address}
                  state={customerData.state}
                  email={customerData.email}
                  electricBill={customerData.electricBill}
                  systemSize={customerData.systemSize}
                  solarProductionMonthly={customerData.solarProductionMonthly}
                  offset={customerData.offset}
                  moduleCost={customerData.moduleCost}
                  total={total}
                  moduleAmount={customerData.moduleAmount}
                  moduleType={customerData.moduleType}
                  batteryAmount={customerData.batteryAmount}
                  batteryCost={customerData.batteryCost}
                  batteryType={customerData.batteryType}
                  storage={customerData.storage}
                  status={true}
                  registered={true}
                  batteryOnly={customerData.batteryOnly}
                />
                {/* <Total
              addressClick={addressClick}
              address={formState.address}
              state={formState.state}
              email={formState.email}
              systemSize={systemSize}
              solarProductionMonthly={responseState.solarProductionMonthly}
              offset={offset}
              moduleCost={subtotal}
              total={total}
              setTotal={setTotal}
              subtotalWithBattery={subtotalWithBattery}
              moduleAmount={moduleAmount}
              moduleType={selected}
              batteryAmount={batteryAmount}
              batteryCost={batteryCost}
              batteryType={batteryType}
              backButton={backButton}
              status={status}
              setStatus={setStatus}
              selectedFinance={selectedFinance}
              setSelectedFinance={setSelectedFinance}
              batteryOnly={batteryOnly}
            /> */}
            </>
          
        ) :
          <QuoteInnerWrapper>
            <br />
            <br />
            <QuoteHeader3>
              <Grid height='4rem' fill='#1BA8F1'/>
            </QuoteHeader3>
            <QuoteHeader3>Loading</QuoteHeader3>
          </QuoteInnerWrapper>
        }
        
    </MainOutline>
  )
}

export default Quote