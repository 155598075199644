import styled from 'styled-components';
import Autocomplete from 'react-google-autocomplete';
import background from '../../images/LunexPower.webp';
import { IoMdLocate } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import { FiPlus } from 'react-icons/fi';
import { FiMinus } from 'react-icons/fi';
import { CgProfile } from 'react-icons/cg';
import { motion } from 'framer-motion';

export const QuoteOuterContainer = styled.div`
    /* font-family: 'Manrope'; */
`;


export const TopBar = styled.div`
    height: 70px;
    width: 100%;
    background-color: #fff;
    position: fixed;
    z-index: 5;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 500px) {
        height: 50px;
    }
`;

export const LunexHeaderLogo = styled.img`
    height: 60px;
    margin-top: 5px;
    margin-left: 10px;
    @media screen and (max-width: 500px) {
        height: 30px;
        margin-top: 10px;
    }
`

export const ProfileWrapper = styled.div`
    position: absolute;
    right: 0;
`

export const NavbarLogout = styled.div`
    color: #1BA8F1;
    position: absolute;
    bottom: 28px;
    right: 60px;

    @media screen and (max-width: 500px) {
        bottom: 19px;
        right: 40px;
    }

    &:hover {
        color: #ffa500;
    }
`

export const LoginLink = styled.div`
    font-size:25px;
    margin: 20px;
    color: #1BA8F1;
    text-decoration: none;
    @media screen and (max-width: 500px) {
        font-size:15px;
        margin: 15px;
    }

    &:hover {
        color: #ffa500;
    }
`

export const ProfileIcon = styled(CgProfile)`
    font-size:37px;
    margin: 15px;
    color: #1BA8F1;
    @media screen and (max-width: 500px) {
        font-size:25px;
        margin: 12px;
    }

    &:hover {
        color: #ffa500;
    }

`

export const QuoteBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 0;
`;

export const QuoteBgMobile = styled.div`
    display: none;
    @media screen and (max-width: 500px) {
        display: flex;
        transition: all 0.2s ease-in-out;
    }
`;

export const ImageBg = styled.img`
    /* margin-top: -100px; */
    margin-left: -130px;
    width: 100%;
    height: 100%;
    background-image: url(${background});
    background-size: cover;
    z-index: 0;
    @media screen and (max-width: 500px) {
        display: none;
    }
`;

export const ImageBgMobile = styled.img`
    @media screen and (max-width: 500px) {
        width:100%;
        height: 200px;
        object-fit: cover;
    }
    
`;

export const QuoteOuterWrapper = styled.div`
    

`;

export const QuoteBackgroundWrapper = styled.div`
    
`;

export const QuoteInnerContainer = styled.div`
    max-width: 500px;
    height: 100%; 
    padding-top: 70px;
    right: 0;
    background-color: #fff;
    z-index: 3;
    /* position: absolute; */
    position: fixed;
    overflow: auto;

    @media screen and (max-width: 500px) {
        width: 100%;
        padding-top: 50px;
        /* height: 100vh;  */
        position: absolute;
        overflow: visible;
    }
    
`;

export const QuoteInnerWrapper = styled.div`
    padding-bottom: 80px;
    width: 480px;
    margin: auto;
    align-items: center;
    min-height: 100%-80px;
    @media screen and (max-width: 480px) {
        width: 90%;
    }
`;

export const QuoteHeader = styled.h1`
    text-align: center;
`;

export const QuoteHeader3 = styled.h3`
    text-align: center;
`;

export const QuoteAutocomplete = styled(Autocomplete)`
    background-color: #E8E8E8;
    border-radius: 5px;
    width: 100%;
    height: 40px;
`;

export const QuoteInputTitle = styled.label`
    font-size: 1.5rem;
    font-weight: 100;
    color: #000;
    display: block;
    text-align: left;
`;

export const QuoteInputTitleCheckbox = styled.label`
    font-size: 1.5rem;
    font-weight: 100;
    color: #000;
    display: block;
    text-align: center;
    @media screen and (max-width: 480px) {
        font-size: 1.3rem;
    }

`;

export const StorageTitle = styled.label`
    font-size: 1.8rem;
    font-weight: 700;
    color: #000;
    display: block;
    text-align: center;
    padding: 20px;
`;

export const QuoteTextTitle = styled.label`
    font-size: 1.5rem;
    font-weight: 500;
    color: #000;
    display: block;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
`;

export const QuoteTextSubtitle = styled.label`
    font-size: 1rem;
    font-weight: 200;
    color: #000;
    display: block;
    text-align: center;
`;

export const QuoteLeaseNumber = styled.span`
    font-size: 2.5rem;
    font-weight: bold;
    position: relative;
    bottom: -5px;
    color: #1BA8F1; /* This is a bright blue color, but you can adjust to any other blue you prefer */
`;

export const QuoteLink = styled.a`
    font-size: 1rem;
    font-weight: 200;
    color: #000;
    display: block;
    text-align: center;
`;

export const QuoteTextTotalWrapper = styled.div`
    margin: auto;
    width:80%;
    display: flex;
    flex-direction: row;
`

export const QuoteDropdownTotalWrapper = styled.div`
    margin-left: auto;
    margin-right: auto;
    width:85%;
    display: flex;
    flex-direction: row;
    /* margin-top: 20px; */
    margin-bottom: 20px;
    justify-content: center;
`

export const QuoteTextTotalFirst = styled.div`
    font-size: 1.3rem;
    text-align: left;
    margin-right: 0;
    margin-top: auto;
    margin-bottom: auto;

`

export const QuoteTextTotalLast = styled.div`
    font-size: 1.3rem;
    margin-left: auto;
    margin-right: 0;
`

export const QuoteTextTotalFirstItalic = styled.div`
    font-size: 1.3rem;
    text-align: left;
    margin-right: 0;
    margin-top: auto;
    margin-bottom: auto;
    font-style: italic;

`

export const QuoteTextTotalLastItalic = styled.div`
    font-size: 1.3rem;
    margin-left: auto;
    margin-right: 0;
    font-style: italic;
`

export const QuoteTotalLeaseNumber = styled.span`
    font-size: 2.5rem;
    font-weight: bold;
    color: #1BA8F1;
`

export const DownloadLink = styled.a`
    font-size: 1rem;
    font-weight: 200;
    color: #000;
    display: block;
    text-align: center;
    text-decoration-line: underline;
    text-decoration-color: black;
    text-align: center;
`;

export const QuoteAddressWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const QuoteInputWrapper = styled.div`
    width: 350px;
    margin: auto;
    display: block;
    @media screen and (max-width: 480px) {
        width: 300px;
    }


`;

export const QuoteTextWrapper = styled.div`
    width: 300px;
    margin: auto;
    /* display: flex; */
    @media screen and (max-width: 480px) {
        width: 100%;
    }
`;

export const QuoteRemoveWrapper = styled.div`
    width: 300px;
    background-color: ${({selected}) => (selected ? 'rgba(255,165,0,0.5)' : '#fff')};
    transition: all 0.2s ease-in-out;
    border-radius: 10px;
    padding: 15px;
    margin: auto;
    display: block;
`;

export const QuoteInput = styled.input`
    background-color: #E8E8E8;
    border-radius: 5px;
    width: 340px;
    height: 40px;

    @media screen and (max-width: 480px) {
        width: 97%;
    }
`;

export const QuoteInputCheckbox = styled.input`
    background-color: #E8E8E8;
    border-radius: 5px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
    @media screen and (max-width: 480px) {
        /* margin-bottom: 10px; */
        /* width: 97%; */
    }
`;

export const QuoteInputLocateIcon = styled(IoMdLocate)`
    position: absolute;
    transform: translateX(150px);
    @media screen and (max-width: 480px) {
        transform: translateX(130px);
    }
`;

export const QuoteInputDeleteIcon = styled(IoMdClose)`
    position: absolute;
    transform: translateX(150px);
    @media screen and (max-width: 480px) {
        transform: translateX(130px);
    }
`;

export const EnergyUsageColumn = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    flex: 1;
`

export const EnergyWrapper = styled.div`
    margin: auto;
    width: 80%;
`;

export const GridWrapper = styled.div`
    margin: 10px;
`;

export const GridRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* max-width:200px; */
    width: 100%;
`;

export const GridColumn = styled.div`
    display: flex;
    background-color: ${({selected}) => (selected ? 'rgba(255,165,0,0.5)' : '#fff')};
    transition: all 0.2s ease-in-out;
    border-radius: 15px;
    max-width: 220px;

    flex-direction: column;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 10px;
`;

export const CounterWrapper = styled.div`
    display: flex;
    flex-direction: row;
    border-radius: 50px;
    border: 2px solid black;
`;

export const CounterMotion = styled(motion.div)`
    
`

export const CounterMinusIcon = styled(FiMinus)`
    height:28px;
    margin: 7px;
    color: ${({disabled}) => (disabled ? '#808080' : '#000')};

`;

export const CounterPlusIcon = styled(FiPlus)`
    height:28px;
    margin: 7px;
    color: ${({disabled}) => (disabled ? '#808080' : '#000')};
`;

export const CounterNumber = styled.div`
    font-size: 20px;

    margin: 6px;
    color: #000;
`;

export const SolarImages = styled.img`
    height:300px;
    width: 180px;
`

export const BatteryImages = styled.img`
    width: 180px;
    height: auto;
`;

export const TextWrapper = styled.div`
    margin: auto;
    width: 80%;
`;

export const TextCentered = styled.div`
    font-size: 1rem;
    color: grey;
    text-align: center;
`

export const IncentiveLine = styled.div`
    margin: auto;
    width: 80%;
    border-bottom: 1px solid grey;
`;

export const IncentiveText = styled.div`
    font-size: 1rem;
    color: grey;
`

export const CaptchaContainer = styled.div`
    margin: auto;
    align-items: center;
    width: 300px;
    padding-bottom:5px;
`;

export const ButtonContainer = styled.div`
    margin: auto;
    display: flex;
    flex-direction:row;
    /* display: block; */
    align-items: center;
    width: 100%;
`;

export const LoadingWrapper = styled.div`
    height: 200px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`

export const CreditCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 150px;
`

export const NextButton = styled.button`
    border-radius: 50px;
    /* width: 200px; */
    width: ${props => props.loading ? '230px' : 'auto'};
    height: ${props => props.loading ? '50px' : 'auto'};
    border: 2px solid white;
    background: #1BA8F1;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir_Book';
    margin-left: auto;
    margin-right: auto;
    &:disabled {
        background: #808080;
        pointer-events: none;
    }
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffa500;
        color: #fff;
    }
`;

export const RemoveButton = styled.button`
    border-radius: 50px;
    border: 2px solid white;
    background: #ff0000;
    white-space: nowrap;
    padding: 12px 30px;
    color: #fff;
    font-size: 16px;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    font-family: 'Avenir_Book';
    margin-left: auto;
    margin-right: auto; 
    &:disabled {
        background: #808080;
        pointer-events: none;
    }
    &:hover {
        transition: all 0.2s ease-in-out;
        background: #ffa500;
        color: #fff;
    }
`;

export const RegistrationErrorWrapper = styled.div`
    width: 350px;
    text-align: center;
    margin: auto;
    display: block;

    @media screen and (max-width: 480px) {
        width: 300px;
    }

`

export const ErrorWrapper = styled.div`
    width: 350px;
    /* text-align: center; */
    margin: auto;
    display: block;
`

export const ErrorText = styled.div`
    color: #c9444d;
    font-size: 0.75rem;
    margin-top: 0.25rem;
`

export const RemeberMeWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* background-color: green; */

    /* @media screen and (max-width: 480px) {
        width: 300px;
    } */
`

export const RememberMeText = styled.div`
    color: #000;
    font-size: 0.75rem;
    margin-top: 0.25rem;
`

export const RememberMeCheckbox = styled.input`
    margin-top: 8px;
`

export const AddressUrl = styled.div`
    color: grey;
    text-decoration-line: underline;
    text-decoration-color: black;
    text-align: center;

    &:hover {
        color: #ffa500;
        text-decoration-color: #ffa500;
    }

`;