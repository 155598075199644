import React from 'react'
import { 
  ImageBg, 
  LoginLink, 
  LunexHeaderLogo, 
  NavbarLogout, 
  ProfileWrapper, 
  QuoteBg, 
  QuoteInnerContainer, 
  QuoteOuterContainer, 
  TopBar 
} from './QuoteElements'
import logo from '../../images/lunexdirectlogo.jpg';
import { Link, useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import Chat from '../Chat/Chat';

const MainOutline = ({
  showLogout,
  children
}) => {
  // const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
      await logout();
      // navigate('/login');
  }

  return (
    <>
    <QuoteOuterContainer>
        <TopBar>
          <Link to='/'>
            <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'}/>
          </Link>
            <ProfileWrapper>
              {showLogout &&
                <Link to='/login' style={{textDecoration:'none'}}>
                  <LoginLink onClick={signOut}>Logout</LoginLink>
                </Link>
              }
              {!showLogout &&
                <Link to='/login' style={{textDecoration:'none'}}>
                  <LoginLink>Login</LoginLink>
                </Link> 
              }
                           
            </ProfileWrapper>

          
        </TopBar>
        {/* <QuoteOuterWrapper></QuoteOuterWrapper> */}
        <QuoteBg>
            <ImageBg />
        </QuoteBg>
        <QuoteInnerContainer>
          {children}
        </QuoteInnerContainer>
        
    </QuoteOuterContainer>
    <Chat />
    </>
  )
}

export default MainOutline