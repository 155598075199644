import React, { useEffect } from 'react'
import { 
    AddressUrl, 
    ButtonContainer, 
    EnergyUsageColumn, 
    EnergyWrapper, 
    GridRow, 
    GridWrapper, 
    NextButton, 
    QuoteHeader, 
    QuoteInnerWrapper, 
    QuoteInputWrapper, 
    QuoteLeaseNumber, 
    QuoteTextSubtitle, 
    QuoteTextTitle, 
    QuoteTextTotalFirst, 
    QuoteTextTotalWrapper, 
    QuoteTextWrapper,
} from './QuoteElements';
import { ModuleData } from './ModuleData';
import { ModuleInfo } from './ModuleInfo';


const Modules = ({
    addressClick, 
    formState, 
    responseState,
    moduleAmount, 
    systemSize, 
    total,
    offset, 
    selected,  
    subtotal, 
    nextButton, 
    setSelected,
    setSystemSize,
    setOffset,
    setModuleAmount,
    setSubtotal,
    batteryCost
}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  
    return (
    <QuoteInnerWrapper>

        <QuoteHeader>Choose your Solar Modules</QuoteHeader>
        <QuoteInputWrapper>
            <AddressUrl onClick={addressClick} >{formState.address}</AddressUrl>
        </QuoteInputWrapper>
        <br />
        <EnergyWrapper>
            <GridRow>
                <EnergyUsageColumn>
                    <QuoteTextTitle>{moduleAmount}</QuoteTextTitle>
                    <QuoteTextSubtitle>Module Amount</QuoteTextSubtitle>
                </EnergyUsageColumn>
                <EnergyUsageColumn>
                    <QuoteTextTitle>{(Number(systemSize)).toLocaleString(undefined,{
                        minimumFractionDigits:1,
                        maximumFractionDigits:2
                        })} kW
                    </QuoteTextTitle>
                    <QuoteTextSubtitle>System Size</QuoteTextSubtitle>
                </EnergyUsageColumn>
            <EnergyUsageColumn>
                <QuoteTextTitle>{(Math.floor(Number(offset))).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                    })}%
                </QuoteTextTitle>
                <QuoteTextSubtitle>Energy Offset</QuoteTextSubtitle>
            </EnergyUsageColumn>
            </GridRow>
        </EnergyWrapper>
        <br />
        <GridWrapper>
            <GridRow>
                {ModuleData.map((module) => {
                    return <ModuleInfo 
                        formState={formState}
                        responseState={responseState}
                        moduleAmount={moduleAmount} 
                        systemSize={systemSize}
                        offset={offset}
                        selected={selected}
                        subtotal={subtotal}
                        nextButton={nextButton}
                        setSelected={setSelected}
                        setSystemSize={setSystemSize}
                        setOffset={setOffset}
                        setModuleAmount={setModuleAmount}
                        setSubtotal={setSubtotal}
                        batteryCost={batteryCost}
                        key={module.name}
                        module={module}
                    />
                } )}
            </GridRow>
        </GridWrapper>
        
        <br />
        <QuoteTextWrapper>
            <QuoteTextTitle>Lease Payment: 
                <QuoteLeaseNumber> ${(Number(total.leaseMonthlyPayment)).toLocaleString(undefined,{
                    minimumFractionDigits:0,
                    maximumFractionDigits:0
                })}</QuoteLeaseNumber>
            </QuoteTextTitle>
        </QuoteTextWrapper>
        <br />
        <QuoteTextWrapper>
            <QuoteTextTitle>Cash Subtotal: ${(Number(subtotal)+Number(batteryCost)).toLocaleString(undefined,{
                minimumFractionDigits:0,
                maximumFractionDigits:0
                })}
            </QuoteTextTitle>
        </QuoteTextWrapper>
        <br />
        <QuoteTextWrapper>
            <QuoteTextSubtitle>Continue to choose your storage options</QuoteTextSubtitle>
        </QuoteTextWrapper>
        <br />
        <ButtonContainer>
            <NextButton onClick={nextButton}>Next</NextButton>
        </ButtonContainer>
        </QuoteInnerWrapper>
  )
}

export default Modules