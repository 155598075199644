import {
    useState, 
    React 
} from "react";
import { 
    Link, 
    useParams 
} from "react-router-dom";
import axios from "../../api/axios";
import MainOutline from "./MainOutline";
import { 
    NextButton, 
    QuoteHeader, 
    QuoteInnerWrapper, 
    QuoteTextSubtitle 
} from "./QuoteElements";

const Confirm = () => {
    let { confirmationCode } = useParams(); 
    const [status, setStatus] = useState(false);

    axios.get("/confirm/" + confirmationCode)
        .then(response => {
        if(response.status !== 200){
            // console.log("ERROR");
        }
        else{
            setStatus(true);
        }
    });
  return (
    <MainOutline>
        <QuoteInnerWrapper>
        <br />
        <br />
        {status &&
            <>
                <QuoteHeader>Account Confirmed</QuoteHeader>
                <QuoteTextSubtitle>
                    <Link to={"/login"} style={{ textDecoration: 'none' }}>
                        <NextButton>Login</NextButton>
                    </Link>
                </QuoteTextSubtitle>
            </>
        }
        {!status &&
            <QuoteHeader>Account Not Verified</QuoteHeader>
        }
        </QuoteInnerWrapper>
    </MainOutline>
  )
}

export default Confirm