import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IconContext } from 'react-icons'
import lunex from '../../images/LunexPower.webp';
// import logo from '../../images/lunexdirectlogo.jpg';
import TagManager from "react-gtm-module";

import { 
    ButtonContainer, 
    ErrorText, 
    ImageBgMobile, 
    NextButton, 
    QuoteAddressWrapper, 
    QuoteAutocomplete, 
    QuoteBgMobile, 
    QuoteHeader, 
    QuoteInnerWrapper, 
    QuoteInput, 
    QuoteInputDeleteIcon, 
    QuoteInputLocateIcon, 
    QuoteInputTitle, 
    QuoteInputWrapper, 
    RegistrationErrorWrapper
} from './QuoteElements'
import { Oval } from 'react-loading-icons';

const Locate = ({
    formState,
    setFormState,
    initialFormState,
    section,
    setSection,
    nextButton,
    updateFormControl,
    submitting,
    customerErrMesg,
    setCustomerErrMesg,
    loadingTexts,
    loadingTextIndex
}) => {

  const [showLocate, setShowLocate] = useState(false);

  const onPlaceSelected = useRef(() => undefined);

  const validStates = [
    'FL',
    'MA',
    'RI',
    'CT'
  ]
  
  const updateFormControlAddy = useCallback((event) => {
    setCustomerErrMesg('');
    var zip = 0;
    for(let i = 0; i < event.address_components.length; i++){
      if(event.address_components[i].types[0] === 'postal_code'){
        zip = i;
      }
    }

    var state = 0;
    for(let i = 0; i < event.address_components.length; i++){
      if(event.address_components[i].types[0] === 'administrative_area_level_1'){
        state = i;
      }
    }

    const updatedFormState = { ...formState };
    updatedFormState['zip'] = event.address_components[zip].short_name;
    updatedFormState['address'] = event.formatted_address;
    updatedFormState['state'] = event.address_components[state].short_name;
    setFormState(updatedFormState);
  }, [formState,setFormState,setCustomerErrMesg]);

  const handleLocate = async () => {
    setShowLocate(true);
    try{
      navigator.geolocation.getCurrentPosition(async function(position) {
        const url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&key=' + process.env.REACT_APP_GOOGLE_MAPS_API

        const result = await axios.get(url);

        // console.log("google geocode resp =", result);

        updateFormControlAddy(result.data.results[0]); 

      });
    }
    catch(error){
      // console.log("Error encounterd while getting location ", error);
    }
  }

  const handleDelete = () => {
    setCustomerErrMesg('');
    setShowLocate(false);
    const updatedFormState = { ...formState };
    updatedFormState['address'] = '';
    setFormState(updatedFormState);
  }
  useEffect(() => {
    onPlaceSelected.current = (place) => {
      updateFormControlAddy(place[0]);
    };
  }, [updateFormControlAddy]);

  const nextButtonLocate = () =>  {
    TagManager.dataLayer({
      gtmId: "GTM-MD88S8R",
      dataLayer: {
        event: 'locate_next_clicked'
      }
    });
    if(!validStates.includes(formState.state))
    {
      setCustomerErrMesg("Sorry, we are not available in your state yet");
      TagManager.dataLayer({
        gtmId: "GTM-MD88S8R",
        dataLayer: {
          event: 'locate_next_clicked_invalid_state'
        }
      });
    }
    else{
      setShowLocate(false);
      nextButton();
      setCustomerErrMesg("");
    }
  }

  const handleKeyPress = (e) => {
    if(e.key === 'Enter' || e.which === 13){
      nextButtonLocate();
      // call your method here
     }
  }

  return (
    <>
      <QuoteBgMobile>
        <ImageBgMobile src={lunex} alt={'Lunex Direct'}/>
      </QuoteBgMobile>
      <QuoteInnerWrapper>
          
          <QuoteHeader>See your Solar Difference with Lunex Direct</QuoteHeader>
          {/* <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'}/> */}
          <QuoteInputWrapper>
          <QuoteInputTitle htmlFor='address'>Address</QuoteInputTitle>
          <QuoteAddressWrapper>
            <IconContext.Provider
                value={{ size: '35px' }}
            >
            {!showLocate &&
              <>
                <QuoteAutocomplete 
                    apiKey="AIzaSyAOAiAN5-83Fjxb45YLTjxGtBkAVaY1_cI"
                    onPlaceSelected={(...args) => onPlaceSelected.current(args)}
                    options={{
                        types: ["address"],
                        componentRestrictions: { country: "us" },
                    }}
                    
                    id='address'
                    defaultValue={formState.address}                      
                />
                <QuoteInputLocateIcon 
                  onClick={handleLocate}
                />
              </>
            }
            {showLocate &&
              <>
                <QuoteInput type='text' id='address' value={formState.address} onChange={(updateFormControlAddy)}/>
                <QuoteInputDeleteIcon 
                    onClick={handleDelete}
                  />
              </>
            }
            
            </IconContext.Provider>
          </QuoteAddressWrapper>
          </QuoteInputWrapper>
          <br />
          <QuoteInputWrapper>
              <QuoteInputTitle htmlFor='electricBill'>Average Monthly Electric Bill</QuoteInputTitle>
              <QuoteInput type='text' id='electricBill' value={formState.electricBill} onChange={(updateFormControl)} onKeyPress={handleKeyPress}/>
          </QuoteInputWrapper>
          <br />
          {!submitting&&
            <ButtonContainer>
              <NextButton onClick={nextButtonLocate} disabled={!formState.address || !formState.electricBill} >Next</NextButton>
            </ButtonContainer>
          }
          {submitting &&
            <>
              <ButtonContainer>
                <NextButton>
                  <Oval height='1.5rem'/>
                </NextButton>
              </ButtonContainer>
              <RegistrationErrorWrapper>
                {loadingTexts[loadingTextIndex]}
              </RegistrationErrorWrapper>

            </>
          }
          {customerErrMesg &&
            <RegistrationErrorWrapper>
                <ErrorText>{customerErrMesg}</ErrorText>
            </RegistrationErrorWrapper>
          }
      </QuoteInnerWrapper>
    </>
    )
}

export default Locate