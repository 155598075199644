import React, { useEffect, useState } from 'react'
import { Widget, addResponseMessage, toggleMsgLoader } from 'react-chat-widget';

import 'react-chat-widget/lib/styles.css';
import './chat.css';

const Chat = () => {
    // const initialFormState = {
    //     prompt: '',
    //     response: ''
    // };

    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState('');

    // const [formState, setFormState] = useState(initialFormState);
    // const [submitting, setSubmitting] = useState(false);
    const [chatWindowOpen, setChatWindowOpen] = useState(true);

    const handleToggle = (chatWindowOpen) => {
        setChatWindowOpen(!chatWindowOpen);
    };

    

  useEffect(() => {
    // addResponseMessage('Welcome to this awesome chat!');
    
    const postSubmission = async () => {
        //   const state = { ...formState };
        //   console.log("prompt =", prompt);
          if(prompt){
            try {
      
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
      
                var raw = JSON.stringify({
                  "prompt": prompt
                });
      
                var requestOptions = {
                  method: 'POST',
                  headers: myHeaders,
                  body: raw,
                  redirect: 'follow'
                };
      
                var result = await fetch(process.env.REACT_APP_API_URL + "/chat", requestOptions)
                  .then(response => response.text())
                  .catch(error => console.log('error', error));
            
                setResponse(JSON.parse(result));
                setPrompt('');
      
                
      
              } catch (error) {
                  console.log(error);
              }
          }
        
    };
    if(prompt){
        postSubmission();
        toggleMsgLoader();
        
    }
    if(response){
        addResponseMessage(response);
        setResponse('');
    }
    
    
  }, [prompt, response]);

  const handleNewUserMessage = async (newMessage) => {
    toggleMsgLoader();
    setPrompt(newMessage);
  };
  return (
    <div>
        <Widget 
            handleNewUserMessage={handleNewUserMessage}
            handleToggle={handleToggle}
            title="Questions?"
            subtitle="Chat with Lunex now"
            emojis
        />
    </div>
  )
}

export default Chat