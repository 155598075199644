import React, { useEffect } from 'react'
import { 
    AddressUrl, 
    ButtonContainer, 
    ErrorText, 
    ImageBgMobile, 
    NextButton, 
    QuoteBgMobile, 
    QuoteHeader, 
    QuoteInnerWrapper, 
    QuoteInput, 
    QuoteInputCheckbox, 
    QuoteInputTitle, 
    QuoteInputTitleCheckbox, 
    QuoteInputWrapper, 
    QuoteTextSubtitle, 
    QuoteTextWrapper, 
    RegistrationErrorWrapper
} from './QuoteElements'
import { Oval } from 'react-loading-icons'
import lunex from '../../images/LunexPower.webp';

const Customer = ({
    addressClick,
    formState,
    updateFormControl,
    errorFormState,
    nextButton,
    nextClicked,
    dataLoaded,
    submitting,
    customerErrMesg,
    batteryOnly,
    setBatteryOnly
}) => {

    useEffect(() => {
        setBatteryOnly(false);
    }, [setBatteryOnly]);

    const handleCheckbox = () => {
        setBatteryOnly(!batteryOnly);
      };
    

  return (
    <>
        <QuoteBgMobile>
            <ImageBgMobile src={lunex} alt={'Lunex Direct'}/>
        </QuoteBgMobile>
        <QuoteInnerWrapper>
            <QuoteHeader>See your Solar Difference with Lunex Direct</QuoteHeader>
            {/* <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'}/> */}
            <QuoteInputWrapper>
                <AddressUrl onClick={addressClick} >{formState.address}</AddressUrl>
            </QuoteInputWrapper>
            <br />
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='name'>Name</QuoteInputTitle>
                <QuoteInput type='text' id='name' value={formState.name} onChange={updateFormControl}/>
            </QuoteInputWrapper>
            <br />
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='email'>Email</QuoteInputTitle>
                <QuoteInput type='text' id='email' value={formState.email} onChange={updateFormControl}/>
            </QuoteInputWrapper>
            <br />
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='phone'>Phone</QuoteInputTitle>
                <QuoteInput type='text' id='phone' value={formState.phone} onChange={updateFormControl}/>
            </QuoteInputWrapper>
            <br />
            <QuoteInputWrapper>
                <QuoteInputTitleCheckbox>
                    Only need battery storage?
                    <QuoteInputCheckbox type="checkbox" checked={batteryOnly} onChange={handleCheckbox} />       
                </QuoteInputTitleCheckbox>

            </QuoteInputWrapper>
            
            <br />
            <QuoteTextWrapper>
                <QuoteTextSubtitle>We will not spam you or share your information with anyone</QuoteTextSubtitle>
            </QuoteTextWrapper>
            <br />
            <ButtonContainer>
                {!nextClicked &&
                    <NextButton onClick={nextButton} disabled={!formState.name || !errorFormState.hasEmailAndIsValid}>GET INSTANT PRICING</NextButton>
                }
                {nextClicked && !dataLoaded &&
                    <NextButton loading={true} disabled={true}>
                        <Oval height='1.5rem'/>
                    </NextButton>                      
                }
            </ButtonContainer>
            {customerErrMesg &&
                <RegistrationErrorWrapper>
                    <ErrorText>{customerErrMesg}</ErrorText>
                </RegistrationErrorWrapper>
            }
        </QuoteInnerWrapper>
    </>
  )
}

export default Customer