import axios from 'axios'
import React, { useState } from 'react'
import { 
    ButtonContainer,
    ErrorText, 
    ErrorWrapper, 
    NextButton, 
    QuoteHeader3, 
    QuoteInnerWrapper, 
    QuoteInput, 
    QuoteInputTitle, 
    QuoteInputWrapper, 
    QuoteTextWrapper,
    RegistrationErrorWrapper
} from './QuoteElements'
import { Oval } from 'react-loading-icons'

const Register = ({
    email,
    backButton
}) => {

    const initialRegisterForm = {
        password: '',
        matchPassword: ''
    }

    const initialErrorForm = {
        passwordError: true,
        matchPasswordError: true,
    }

    const [registerForm, setRegisterForm] = useState(initialRegisterForm);
    const [error, setError] = useState(initialErrorForm);
    const [touched, setTouched] = useState(false);
    const [matchTouched, setMatchTouched] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errMesg, setErrMsg] = useState('');
    const [registered, setRegistered] = useState(false);

    const updateRegisterForm = (event) => {
        event.preventDefault();

        // console.log("event = ", event.target.id);
        const { id, value } = event.target;
        const formKey = id;

        const updatedRegisterForm = {...registerForm};
        const updatedErrorState = {...error};

        if(id === 'password'){
            if(value.length === 0){
                updatedErrorState.passwordError = true;
                updatedRegisterForm[formKey] = value;
            }
            if(!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/i)){
                updatedErrorState.passwordError = true;
                // console.log("password invalid");
                updatedRegisterForm[formKey] = value;
            }
            else{
                updatedErrorState.passwordError = false;
                updatedRegisterForm[formKey] = value;
            }
        }
        if(id === 'matchPassword'){
            if(!value.match(registerForm.password)){
                updatedErrorState.matchPasswordError = true;
                // console.log("Password does not match");
                updatedRegisterForm[formKey] = value;
            }
            else{
                // console.log("password matches")
                updatedErrorState.matchPasswordError = false;
                updatedRegisterForm[formKey] = value;
            }
        }

        
        setRegisterForm(updatedRegisterForm);
        setError(updatedErrorState);
        // console.log(error);

    }

    const registerButton = async() => {
        setSubmitting(true);

        // console.log('registering user');
        var data = JSON.stringify({
            "email": email,
            "pwd": registerForm['password']
          });
          
          var config = {
            method: 'put',
            url: process.env.REACT_APP_API_URL + '/register',
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            // console.log("Success =", JSON.stringify(response.status));
            if(response.status === 201){
                // console.log("Inside status check");
                setRegistered(true);
            }
          })
          .catch(function (error) {
            // console.log(error);
            if (!error?.response) {
                setErrMsg('No Server Response');
            } else if (error.response?.status === 204) {
                setErrMsg('No email found');
            } else {
                setErrMsg('Registration Failed')
            }
          });
        setSubmitting(false);
 
    }

  return (
    <QuoteInnerWrapper>
        {!registered &&
        <>
            <QuoteTextWrapper>
                <QuoteHeader3>Set your password below to secure your unique system.</QuoteHeader3>
            </QuoteTextWrapper>
            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='password'>Password</QuoteInputTitle>
                <QuoteInput type="password" name="password" id='password' value={registerForm.password} onChange={updateRegisterForm} onBlur={() => setTouched(true)}/>
            </QuoteInputWrapper>
            {error.passwordError && touched &&
                <ErrorWrapper>
                    <ErrorText>8-24 characters, uppercase and lowercase letters, number and a special character !@#$%</ErrorText>
                </ErrorWrapper>
            }
            <br />

            <QuoteInputWrapper>
                <QuoteInputTitle htmlFor='matchPassword'>Confirm Password</QuoteInputTitle>
                <QuoteInput type='password' name='password' id='matchPassword' value={registerForm.matchPassword} onChange={updateRegisterForm} onBlur={() => setMatchTouched(true)}/>
            </QuoteInputWrapper>
            {error.matchPasswordError && matchTouched &&
                <ErrorWrapper>
                    <ErrorText>Must match first password</ErrorText>
                </ErrorWrapper>
            }
            <br />
            <ButtonContainer>
                {!submitting &&
                    <ButtonContainer>
                        <NextButton onClick={backButton}>Back</NextButton>
                        <NextButton onClick={registerButton} disabled={error.passwordError || error.matchPasswordError || submitting}>Register</NextButton>
                    </ButtonContainer>
                }
                {submitting &&
                    <NextButton>
                        <Oval height='1.5rem'/>
                    </NextButton>            
                }
            </ButtonContainer>
            {errMesg &&
                <RegistrationErrorWrapper>
                    <ErrorText>{errMesg}</ErrorText>
                </RegistrationErrorWrapper>
            }
        </>
        }
        {registered &&
            <QuoteTextWrapper>
                <QuoteHeader3>Successfully Registered. Check email to confirm.</QuoteHeader3>
            </QuoteTextWrapper>
        }
        
    </QuoteInnerWrapper>
  )
}

export default Register