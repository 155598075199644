import React, { useEffect, useState } from 'react'
import { 
  AddressUrl, 
  ButtonContainer, 
  CreditCardWrapper, 
  EnergyUsageColumn, 
  EnergyWrapper, 
  GridRow, 
  IncentiveLine, 
  IncentiveText, 
  NextButton, 
  QuoteDropdownTotalWrapper, 
  QuoteHeader, 
  QuoteInnerWrapper, 
  QuoteInputWrapper, 
  QuoteLeaseNumber, 
  QuoteLink, 
  QuoteTextSubtitle, 
  QuoteTextTitle, 
  QuoteTextTotalFirst, 
  QuoteTextTotalFirstItalic, 
  QuoteTextTotalLast, 
  QuoteTextTotalLastItalic, 
  QuoteTextTotalWrapper, 
  QuoteTextWrapper, 
  QuoteTotalLeaseNumber, 
  TextCentered, 
  TextWrapper 
} from './QuoteElements'
import Stripe from './Stripe';
import Register from './Register';
import { StorageData } from './StorageData';
import { ModuleData } from './ModuleData';
import { FinanceData } from './FinanceData';
import { LeaseData } from './LeaseData';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListSubheader from '@mui/material/ListSubheader';
import axios from 'axios';

const Total = ({
  addressClick,
  address,
  state,
  email,
  electricBill,
  systemSize,
  solarProductionMonthly,
  offset,
  total,
  setTotal,
  moduleAmount,
  moduleCost,
  moduleType,
  batteryAmount,
  batteryCost,
  batteryType,
  backButton,
  status,
  registered,
  setStatus,
  selectedFinance,
  setSelectedFinance,
  batteryOnly
}) => {
  // console.log("batt amount ", batteryAmount);
  const financingOptions = FinanceData;
  // const [financed, setFinanced] = useState(financingOptions[0].Option);
  const [showCreditCardField, setShowCreditCardField]= useState(false);
  const [showRegister, setShowRegister]= useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const isDropdownDisabled = showCreditCardField || paymentSuccess || status || showRegister;

  const handleFinancingChange = (e) => {
    
    const selectedOption = e.target.value;
    
  
    // Find the corresponding finance option
    const financeOption = financingOptions.find((option) => option.Option === selectedOption);
    setSelectedFinance(financeOption);

    // console.log("finance option ", financeOption.Type);
    // console.log("selected fin ", selectedFinance);

    const financedTotal = total.totalPriceAfterIncentives / ((100 - financeOption.Fee)/100);
    const financeRate = financeOption.APR / 100;
    const numberOfPayments = financeOption.Term * 12;
    // let financedMonthlyPayment = 0;
    // let leaseMonthlyPayment = 0;
    // let moduleMonthlyPayment = 0;
    // let batteryMonthlyPayment = 0;
    const matchingRateData = LeaseData.find(data => data.State === state);
    // console.log("rate data ", matchingRateData);
    // console.log("state ", state);
    // if(financeOption.Type === 'Lease' && matchingRateData){
    const moduleMonthlyPayment = solarProductionMonthly * matchingRateData.Rate;
    const batteryMonthlyPayment = batteryAmount * 56.87;

    let leaseMonthlyPayment = 0;
    if(batteryOnly){
      leaseMonthlyPayment = batteryMonthlyPayment;
    }
    else{
      leaseMonthlyPayment = moduleMonthlyPayment + batteryMonthlyPayment;
    }
    // console.log("lease fi mo payment", leaseMonthlyPayment);

      // const updatedTotalState = {
      //   ...total,
      //   leaseMonthlyPayment: leaseMonthlyPayment,
      // };
      // setTotal(updatedTotalState);
    // }
    // else if(financeOption.Type === 'Loan') {
    // console.log("loan fi total", financedTotal);
    const financedMonthlyPayment = (financedTotal * (financeRate / 12)) / (1 - (1 + (financeRate / 12)) ** (-numberOfPayments));
    // console.log("loan fi mo payment", financedMonthlyPayment);


      // const updatedTotalState = {
      //   ...total,
        
      // };
      // setTotal(updatedTotalState);

    // }
    // console.log("selected fin Type ", financeOption.Type);

    const updatedTotalState = {
      ...total,
      financeOption: financeOption.Option,
      leaseMonthlyPayment: leaseMonthlyPayment,
      financedMonthlyPayment: financedMonthlyPayment,
      financedTotal: financedTotal,
      financeType: financeOption.Type
    };
    setTotal(updatedTotalState);

    // For lease take the (solar production / 12) *.112
    // For batteries $56.87 per battery / month
    // remove deposit
    // link to sunnova
  
    // Calculate the new total
    // const financedTotal = total.totalPriceAfterIncentives / ((100 - financeOption.Fee)/100);
    // const financeRate = financeOption.APR / 100;
    
    // financedMonthlyPayment = 
    
  };

  // const handleShowField = () => {
  //   setShowCreditCardField(true);
  //   updateCustomerFinancing({
  //     financeOption: total.financeOption,
  //     financedTotal: total.financedTotal,
  //     financedMonthlyPayment: total.financedMonthlyPayment
  //   });
  // }
  const handleShowField = () => {
    setShowRegister(true);

    const financingData = {
      financeOption: total.financeOption,
      financedTotal: total.financedTotal,
      financeType: total.financeType,
      financedMonthlyPayment: total.financedMonthlyPayment,
      status: "registered",
    }

    if(total.financeType === 'Lease') {
      if(!total.batteryOnly){
        financingData.moduleMonthlyPayment = total.moduleMonthlyPayment || null;
      }
      
      financingData.batteryMonthlyPayment = total.batteryMonthlyPayment || null;
    }


    updateCustomerFinancing(financingData);
  }

  async function updateCustomerFinancing(totalValues) {
    // console.log("totalValues update customer ", totalValues);
    var dataObject = {
      "email": email,
      "status":totalValues.status,
      "financeOption": totalValues.financeOption,
      "financedTotal": totalValues.financedTotal,
      "financedMonthlyPayment": totalValues.financedMonthlyPayment,
      "financeType":totalValues.financeType,
      "leaseMonthlyPayment":totalValues.leaseMonthlyPayment,
    };

    if (totalValues.moduleMonthlyPayment) {
      dataObject.moduleMonthlyPayment = totalValues.moduleMonthlyPayment;
    }
    if (totalValues.batteryMonthlyPayment) {
      dataObject.batteryMonthlyPayment = totalValues.batteryMonthlyPayment;
    }

    var data = JSON.stringify(dataObject);

    // console.log("data update ", data);
    
    var config = {
      method: 'put',
      url: process.env.REACT_APP_API_URL + '/estimate',
      headers: { 
        'Content-Type': 'application/json', 
      },
      data : data
    };
    
    await axios(config)
    .then(function (response) {
    })
    .catch(function (error) {
    });
  };
  

  

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  const EnergySection = () => (
    <>
      <EnergyWrapper>
        <GridRow>
          <EnergyUsageColumn>
            {!batteryOnly && (
              <>
                <QuoteTextTitle>{(Number(systemSize)).toLocaleString(undefined,{
                      minimumFractionDigits:1,
                      maximumFractionDigits:2
                  })} kW</QuoteTextTitle>
                <QuoteTextSubtitle>System Size</QuoteTextSubtitle>
              </>
            )}
            {batteryOnly && (
              <>
                <QuoteTextTitle>{batteryAmount}</QuoteTextTitle>
                <QuoteTextSubtitle>Storage Units</QuoteTextSubtitle>
              </>
            )}
          </EnergyUsageColumn>
          <EnergyUsageColumn>
            {batteryAmount !== 0 &&
              <>
                <QuoteTextTitle>{StorageData.map(storage => batteryType===storage.batteryType ? batteryAmount*storage.kw : undefined)} kWh</QuoteTextTitle>
                <QuoteTextSubtitle>Storage Size</QuoteTextSubtitle>
              </>
            }
            {batteryAmount === 0 &&
              <>
                <QuoteTextTitle>{(Number(offset)).toLocaleString(undefined,{
                  minimumFractionDigits:0,
                  maximumFractionDigits:0
                })}%
                </QuoteTextTitle>
                <QuoteTextSubtitle>Energy Offset</QuoteTextSubtitle>
              </>
            } 
          </EnergyUsageColumn>
          {!batteryOnly && (
            <EnergyUsageColumn>
              <QuoteTextTitle>${Number(total.savingsAmount) > 1000 ? (Number(total.savingsAmount)/1000).toLocaleString(undefined,{
                minimumFractionDigits:0,
                maximumFractionDigits:0
              })+ 'k': (Number(total.savingsAmount)).toLocaleString(undefined,{
                minimumFractionDigits:0,
                maximumFractionDigits:0
              })}</QuoteTextTitle>
              <QuoteTextSubtitle>25 Year Savings</QuoteTextSubtitle>
            </EnergyUsageColumn>
          )}
        </GridRow>
      </EnergyWrapper>
      <br />
      <br />
    </>
  )

  const ModuleSection = () => (
    !batteryOnly && (
      <>
        <QuoteTextTotalWrapper>
          <QuoteTextTotalFirst>{moduleAmount} {ModuleData.map((module)=> (moduleType===module.module ? module.brand : undefined))} Modules</QuoteTextTotalFirst>
          {total.financeType === "Lease" &&
            <QuoteTextTotalLast>${(Number(total.moduleMonthlyPayment)).toLocaleString(undefined,{
              minimumFractionDigits:0,
              maximumFractionDigits:0
            })}</QuoteTextTotalLast>
          }
          {total.financeType !== "Lease" && 
            <QuoteTextTotalLast>${(Number(moduleCost)).toLocaleString(undefined,{
              minimumFractionDigits:0,
              maximumFractionDigits:0
            })}</QuoteTextTotalLast>
          }
        </QuoteTextTotalWrapper>
        <TextWrapper>
          <IncentiveText>Including Installation</IncentiveText>
        </TextWrapper>
        <br />
      </>
    )
  )
  const BatterySection = () => (
    batteryAmount > 0 && (
      <>
        <QuoteTextTotalWrapper>
          <QuoteTextTotalFirst>{batteryAmount} {StorageData.map((storage) => (batteryType===storage.batteryType ? storage.name : undefined))} Units</QuoteTextTotalFirst>
          {total.financeType === "Lease" &&
            <QuoteTextTotalLast>${(Number(total.batteryMonthlyPayment)).toLocaleString(undefined,{
              minimumFractionDigits:0,
              maximumFractionDigits:0
            })}</QuoteTextTotalLast>
          }
          {total.financeType !== "Lease" && 
            <QuoteTextTotalLast>${(Number(batteryCost)).toLocaleString(undefined,{
              minimumFractionDigits:0,
              maximumFractionDigits:0
            })}</QuoteTextTotalLast>
          }
        </QuoteTextTotalWrapper>
        
        <br />
        <br />
      </>
    )
  )

  const SubtotalSection = () => (
    total.financeType !== 'Lease' && (
      <>
        <QuoteTextTotalWrapper>
          <QuoteTextTotalFirst>Subtotal</QuoteTextTotalFirst>
          <QuoteTextTotalLast>${(Number(total.cashPriceBeforeIncentives)).toLocaleString(undefined,{
            minimumFractionDigits:0,
            maximumFractionDigits:0
          })}</QuoteTextTotalLast>
        </QuoteTextTotalWrapper>
        <br />
        <br />
      </>
    
  ));

  const IncentiveSection = () => (
    total.financeType !== 'Lease' && (
      <>
        <IncentiveLine>
          <IncentiveText>Incentives</IncentiveText>
        </IncentiveLine>
        <QuoteTextTotalWrapper>
          <QuoteTextTotalFirst>Federal Tax Credit</QuoteTextTotalFirst>
          <QuoteTextTotalLast>-${(Number(total.incentiveAmount)).toLocaleString(undefined,{
            minimumFractionDigits:0,
            maximumFractionDigits:0
          })}</QuoteTextTotalLast>
        </QuoteTextTotalWrapper>
        <br />
        <br />
      </>
    )
  );

  const CashTotalSection = () => (
    total.financeType === 'Cash' && (
      <>
        <QuoteTextTotalWrapper>
          <QuoteTextTotalFirst>Cash Total</QuoteTextTotalFirst>
          <QuoteTextTotalLast>
            <QuoteTotalLeaseNumber>${(Number(total.totalPriceAfterIncentives)).toLocaleString(undefined,{
            minimumFractionDigits:0,
            maximumFractionDigits:0
          })}</QuoteTotalLeaseNumber>
          </QuoteTextTotalLast>
        </QuoteTextTotalWrapper>
        <br />
        <br />
      </>
      
    )
  );

  const FinancedPriceSection = () => (
    total.financeType !== 'Cash' && (
    <>
      <IncentiveLine>
        <IncentiveText>With Financing</IncentiveText>
      </IncentiveLine>
      {total.financeType === 'Loan' && (
        <>
          <QuoteTextTotalWrapper>
            <QuoteTextTotalFirst>Financed Price</QuoteTextTotalFirst>
            <QuoteTextTotalLast>${(Number(total.financedTotal)).toLocaleString(undefined,{
              minimumFractionDigits:0,
              maximumFractionDigits:0
            })}</QuoteTextTotalLast>
          </QuoteTextTotalWrapper>
          <br />
          <br />
          {!batteryOnly &&
            <>
              <QuoteTextTotalWrapper>
                <QuoteTextTotalFirst>Current Electric Bill</QuoteTextTotalFirst>
                <QuoteTextTotalLastItalic>${(Number(electricBill)).toLocaleString(undefined,{
                  minimumFractionDigits:0,
                  maximumFractionDigits:0
                })}</QuoteTextTotalLastItalic>
              </QuoteTextTotalWrapper>
              <br />
            </>
          }
          
          <QuoteTextTotalWrapper>
            <QuoteTextTotalFirst>{batteryOnly ? 'Monthly Bill' : 'New Monthly Bill'}</QuoteTextTotalFirst>
            <QuoteTextTotalLast>
              <QuoteTotalLeaseNumber>
                ${(Number(total.financedMonthlyPayment)).toLocaleString(undefined,{
                minimumFractionDigits:0,
                maximumFractionDigits:0
              })}
              </QuoteTotalLeaseNumber>
            </QuoteTextTotalLast>
          </QuoteTextTotalWrapper>
          <br />
          <br />
          
        </>
      )}
      {total.financeType === 'Lease' &&
        <>
          {!batteryOnly &&
            <>
              <QuoteTextTotalWrapper>
                <QuoteTextTotalFirst>Current Electric Bill</QuoteTextTotalFirst>
                <QuoteTextTotalLastItalic>${(Number(electricBill)).toLocaleString(undefined,{
                  minimumFractionDigits:0,
                  maximumFractionDigits:0
                })}</QuoteTextTotalLastItalic>
              </QuoteTextTotalWrapper>
              <br />
            </>
          }
          
          <QuoteTextTotalWrapper>
            <QuoteTextTotalFirst>{batteryOnly ? 'Monthly Bill' : 'New Monthly Bill'}</QuoteTextTotalFirst>
            <QuoteTextTotalLast>
              <QuoteTotalLeaseNumber>
                ${(Number(batteryOnly ? total.batteryMonthlyPayment : total.leaseMonthlyPayment)).toLocaleString(undefined,{
                  minimumFractionDigits:0,
                  maximumFractionDigits:0
              })}
              </QuoteTotalLeaseNumber>
            </QuoteTextTotalLast>
          </QuoteTextTotalWrapper>
          <br />
        </>
      }
      
      
    </>
    )
  );

  const DepositReceivedSection = () => (
    status && !registered && (
      <>
        <QuoteTextWrapper>
          <QuoteTextTitle>Deposit received</QuoteTextTitle>
        </QuoteTextWrapper>
        <Register email={email} />
      </>
    )
  );
  

  const ContactUsSection = () => (
    registered && (
      <>
        <QuoteTextWrapper>
          {/* <QuoteTextTitle>Status: Deposit received</QuoteTextTitle> */}
          <QuoteTextSubtitle>We are working on building your system and will be in contact with you</QuoteTextSubtitle>
        </QuoteTextWrapper>
        
          
            <br />
            <QuoteTextWrapper>
              <QuoteTextTitle>Contact Us</QuoteTextTitle>
              <br />
              <QuoteLink href='tel:+1-813-540-8807'>(813)540-8807</QuoteLink>
              <QuoteLink href='mailto:info@lunexpower.com'>info@lunexpower.com</QuoteLink>
              <br />
              {state === 'FL' &&
                <>
                  <QuoteTextSubtitle>4721 N Grady Ave</QuoteTextSubtitle>
                  <QuoteTextSubtitle>Tampa, FL 33614</QuoteTextSubtitle>
                </>
              }
              {state !== 'FL' && 
                <>
                  <QuoteTextSubtitle>95 Prescott Street</QuoteTextSubtitle>
                  <QuoteTextSubtitle>Worcester, MA 01606</QuoteTextSubtitle>
                </>
              }
            </QuoteTextWrapper>
          
        
        
      </>
    )
  );

  return (
    <QuoteInnerWrapper>
      <QuoteHeader>Your System</QuoteHeader>
      <QuoteInputWrapper>
        <AddressUrl onClick={addressClick} >{address}</AddressUrl>
      </QuoteInputWrapper>
      <br />
      <EnergySection />
      <QuoteDropdownTotalWrapper>
        <FormControl>
          <InputLabel id="financing">Financing</InputLabel>
          <Select
              labelId="financing"
              id="financing"
              value={total.financeOption}
              label="Financing"
              onChange={handleFinancingChange}
              disabled={isDropdownDisabled}
          >
              {financingOptions.map((option, index) => (
                <MenuItem
                    key={index}
                    value={option.Option}
                >
                {option.Option}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </QuoteDropdownTotalWrapper>
      <br />
      <ModuleSection />
      <BatterySection />
      <SubtotalSection />
      <IncentiveSection />
      <CashTotalSection />
      <FinancedPriceSection />
      {!showRegister && !registered && (
        <>
          <TextWrapper>
            <TextCentered>Lock in your solar system design! Sign up today, and we'll reach out to ensure you're on the best path forward.</TextCentered>
          </TextWrapper>
          <br />
              
          <ButtonContainer>
              <NextButton onClick={backButton}>Back</NextButton>
              <NextButton onClick={handleShowField}>Sign Up</NextButton>
          </ButtonContainer>
        </>
      )}
      {showRegister && !registered && (
        <>
          <TextWrapper>
            <TextCentered>Lock in your solar system design! Sign up today, and we'll reach out to ensure you're on the best path forward.</TextCentered>
          </TextWrapper>
          <br />
              
          <Register email={email} backButton={backButton}/>
        </>
      )}
      <ContactUsSection />
    </QuoteInnerWrapper>
  )
}

export default Total