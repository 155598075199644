import React, { useEffect } from 'react'
import { 
    AddressUrl, 
    BatteryImages, 
    ButtonContainer, 
    CounterMinusIcon, 
    CounterNumber, 
    CounterPlusIcon, 
    CounterWrapper, 
    DownloadLink, 
    EnergyUsageColumn, 
    EnergyWrapper, 
    GridColumn, 
    GridRow, 
    GridWrapper, 
    NextButton, 
    QuoteHeader, 
    QuoteInnerWrapper, 
    QuoteInputWrapper, 
    QuoteLeaseNumber, 
    QuoteRemoveWrapper, 
    QuoteTextSubtitle, 
    QuoteTextTitle, 
    QuoteTextWrapper, 
    RemoveButton, 
    StorageTitle, 
    TextCentered, 
    TextWrapper
} from './QuoteElements'
import { Oval } from 'react-loading-icons'
import { StorageData } from './StorageData';

const Storage = ({
    addressClick,
    formState,
    batteryAmount,
    systemSize,
    total,
    setTotal,
    offset,
    batteryType,
    batteryCost,
    subtotal,
    subtotalWithBattery,
    backButton,
    nextButton,
    setBatteryAmount,
    setBatteryCost,
    setSubtotalWithBattery,
    setBatteryType,
    submitting,
    batteryOnly
}) => {
    const handleStorage = (storage_prop) => {
        setBatteryAmount(0);
        setBatteryCost(0);
        setSubtotalWithBattery(subtotal);
        setBatteryType(storage_prop);
    
    
        if(storage_prop === 'none'){
          setBatteryAmount(0);
          setBatteryCost(0);
          setSubtotalWithBattery(subtotal);
          setTotal(prevTotal => ({
            ...prevTotal,  // Spread previous total state
            batteryMonthlyPayment: 0,
            leaseMonthlyPayment: prevTotal.moduleMonthlyPayment
        }));
        }
    }

    function updateBatteryValues (operator) {
        const firstBattery = 15000;
        const consecutiveBatteries = 10000;
        const batteryMonthlyPaymentPerBattery = 56.87;  // Monthly payment for each battery
    
        var newBatteryCost = 0;
        var newSubtotal = 0;
        var newBatteryMonthlyPayment = 0;  // New monthly payment based on the number of batteries
    
        const newBatteryAmount = batteryAmount + operator;
    
        if(newBatteryAmount === 0) {
            setBatteryAmount(0);
            setBatteryCost(0);
            setSubtotalWithBattery(subtotal);
            newBatteryMonthlyPayment = 0;  // Setting monthly payment to zero when no batteries
        }
    
        if(newBatteryAmount === 1) {
            setBatteryAmount(1);
            setBatteryCost(firstBattery);
            newSubtotal = Number(subtotal) + Number(firstBattery);
            setSubtotalWithBattery(newSubtotal);
            newBatteryMonthlyPayment = batteryMonthlyPaymentPerBattery;  // Only one battery's monthly payment
        }
    
        if(newBatteryAmount > 1) {
            setBatteryAmount(newBatteryAmount);
            newBatteryCost = firstBattery + (newBatteryAmount - 1) * consecutiveBatteries;
            newSubtotal = Number(subtotal) + Number(newBatteryCost);
            setBatteryCost(newBatteryCost);
            setSubtotalWithBattery(newSubtotal);
            newBatteryMonthlyPayment = newBatteryAmount * batteryMonthlyPaymentPerBattery;  // Monthly payment for all batteries
        }
    
        // Updating the batteryMonthlyPayment within the total state
        setTotal(prevTotal => ({
            ...prevTotal,  // Spread previous total state
            batteryMonthlyPayment: newBatteryMonthlyPayment,
            leaseMonthlyPayment: prevTotal.moduleMonthlyPayment + newBatteryMonthlyPayment // Override batteryMonthlyPayment with new value
        }));
    }
    

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
  return (
    <QuoteInnerWrapper>
        <QuoteHeader>Select Storage Options</QuoteHeader>
        <QuoteInputWrapper>
            <AddressUrl onClick={addressClick} >{formState.address}</AddressUrl>
        </QuoteInputWrapper>
        <br />
        <EnergyWrapper>
            <GridRow>
                <EnergyUsageColumn>
                    <QuoteTextTitle>{batteryAmount}</QuoteTextTitle>
                    <QuoteTextSubtitle>Storage Units</QuoteTextSubtitle>
                </EnergyUsageColumn>
                {!batteryOnly &&
                    <EnergyUsageColumn>
                        <QuoteTextTitle>{(Number(systemSize)).toLocaleString(undefined,{
                                minimumFractionDigits:1,
                                maximumFractionDigits:2
                            })}kW
                        </QuoteTextTitle>
                        <QuoteTextSubtitle>System Size</QuoteTextSubtitle>
                    </EnergyUsageColumn>
                }
                
                <EnergyUsageColumn>
                    <QuoteTextTitle>{
                        batteryType !== 'none' && batteryAmount > 0
                        ? StorageData.map(storage => batteryType === storage.batteryType ? batteryAmount * storage.kw : null)
                        : 0
                    } kWh
                    </QuoteTextTitle>
                    <QuoteTextSubtitle>Storage Size</QuoteTextSubtitle>
                </EnergyUsageColumn>
            </GridRow>
        </EnergyWrapper>
        <br />
        <GridWrapper>
            <GridRow>
            {StorageData.map((storage) => (
                <GridColumn selected={batteryType === storage.batteryType} key={storage.name}>
                    <StorageTitle>{storage.brand}</StorageTitle>
                    <br />
                    <BatteryImages src={storage.image} alt={storage.imageAlt} />
                    <br />
                    <QuoteTextSubtitle>{storage.name}</QuoteTextSubtitle>
                    <QuoteTextSubtitle>{storage.kw} kWh of Storage</QuoteTextSubtitle>
                    <QuoteTextSubtitle>{storage.warranty} year warranty</QuoteTextSubtitle>
                    <DownloadLink href={storage.datasheet} download>Specs</DownloadLink>
                    <br />
                    <QuoteTextSubtitle>${storage.firstUnit} for first unit</QuoteTextSubtitle>
                    <QuoteTextSubtitle>${storage.additionalUnits} per additional units</QuoteTextSubtitle>
                    
                    <br />
                    {batteryType !== storage.batteryType ? 
                        <NextButton onClick={() => handleStorage(storage.batteryType)}>Select</NextButton>
                        :
                        <>
                            <QuoteTextSubtitle>How many would you like?</QuoteTextSubtitle>
                            <br />
                            <CounterWrapper>
                            <CounterMinusIcon onClick={()=> updateBatteryValues(-1)} disabled={!batteryAmount}/>
                            <CounterNumber>{batteryAmount}</CounterNumber>
                            <CounterPlusIcon onClick={()=> updateBatteryValues(1)}/>
                            </CounterWrapper>
                            <QuoteTextSubtitle>Storage Cost: ${batteryCost.toLocaleString(undefined,{
                                minimumFractionDigits:0,
                                maximumFractionDigits:0
                            })}</QuoteTextSubtitle>
                        </>
                    }
                    <br />
                </GridColumn>
            ))}
            </GridRow>
        </GridWrapper>
        <br />
        {!batteryOnly &&
            <>
                <QuoteRemoveWrapper selected={batteryType === 'none'}>
                    {batteryType !== 'none' ?
                        <>
                        <TextWrapper>
                            <TextCentered>Don't want to ensure you have power when the grid goes down?</TextCentered>
                        </TextWrapper>
                        <br />
                        <RemoveButton onClick={() => handleStorage('none')}>Remove</RemoveButton>
                        <br />
                        </> :
                        <QuoteTextTitle>No storage selected</QuoteTextTitle>
                    }
                </QuoteRemoveWrapper>
                <br />
                <QuoteTextWrapper>
                    <QuoteTextTitle>Lease Payment: 
                        <QuoteLeaseNumber> ${(Number(total.leaseMonthlyPayment)).toLocaleString(undefined,{
                            minimumFractionDigits:0,
                            maximumFractionDigits:0
                        })}</QuoteLeaseNumber>
                    </QuoteTextTitle>
                </QuoteTextWrapper>
            </>
        }
        {batteryOnly &&
            <QuoteTextWrapper>
                <QuoteTextTitle>Lease Payment: 
                    <QuoteLeaseNumber> ${(Number(total.batteryMonthlyPayment)).toLocaleString(undefined,{
                        minimumFractionDigits:0,
                        maximumFractionDigits:0
                    })}</QuoteLeaseNumber>
                </QuoteTextTitle>
            </QuoteTextWrapper>
        }
        
        <br />
        <QuoteTextWrapper>
            <QuoteTextTitle>Cash Subtotal: ${Number(subtotalWithBattery).toLocaleString(undefined,{
                minimumFractionDigits:0,
                maximumFractionDigits:0
            })}</QuoteTextTitle>
        </QuoteTextWrapper>
        <br />
        <ButtonContainer>
            {!batteryOnly &&
                <>
                    <NextButton onClick={backButton}>Back</NextButton>
                    {!submitting &&
                        <NextButton onClick={nextButton}>Next</NextButton>
                    }
                </>
            }
            {batteryOnly &&
                <>
                    {!submitting &&
                        <NextButton onClick={nextButton} disabled={!batteryAmount}>Next</NextButton>
                    }
                </>
            }
            {submitting && 
                <NextButton>
                    <Oval height='1.5rem'/>
                </NextButton> 
            }
            
        </ButtonContainer>
    </QuoteInnerWrapper>
  )
}

export default Storage