import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
    // console.log("Use refresh token");
    const { setAuth } = useAuth();

    const refresh = async () => {
        // console.log("calling refresh endpoint");
        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        // console.log("response ", response);
        setAuth(prev => {
            // console.log("prev ", JSON.stringify(prev));
            // console.log("access token inside refresh token ", response.data.accessToken);
            return {
                ...prev,
                accessToken: response.data.accessToken
            }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
